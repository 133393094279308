<template>
    <div class="home">        
        <main id="main">

            <!-- ======= Breadcrumbs ======= -->
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                    <h2>Click Ads</h2>
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Click Ads</li>
                    </ol>
                    </div>

                </div>
            </section><!-- End Breadcrumbs -->

            <!-- ======= Claim Voucher Section ======= -->
            <section class="pricing">
                <div class="container">

                    <div class="row justify-content-center" >
                        <div class="col-md-12 justify-content-center" style="padding-top: 80px;">
                            <div id="container-ff56ead9bcd83fc02c99a9618457334d"></div>
                        </div>


                        <div class="col-lg-8 col-md-8 mt-5 text-center" >
                            <a href="https://www.profitablecpmrate.com/i3ttfkfcpw?key=a370b47ddc84dc99764eef0b1d056430" class="btn btn-md" style="background-color: #BCA37F; color: white;">Click Ads</a>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End Claim Voucher Section -->

        </main><!-- End #main -->
    </div>
</template>

<script>
export default {
    data() {
        return{
           
        }
    },

    mounted() {
        // Buat elemen <script> untuk memuat script eksternal
        const scriptElement = document.createElement('script');
        scriptElement.async = true;
        scriptElement.setAttribute('data-cfasync', 'false');
        scriptElement.src = '//pl24822780.profitablecpmrate.com/ff56ead9bcd83fc02c99a9618457334d/invoke.js';

        // Tambahkan script ke dalam elemen <div> dengan ID yang sesuai
        const container = document.getElementById('container-3eb57ed70d0a015ad11397dee26f9d45');
        container.appendChild(scriptElement);
    }
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #BCA37F;
  color: #ffffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f5f8;
  border-radius: 50px;
}
</style>