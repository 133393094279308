<template>
    <div class="home">        
        <main id="main">

            <!-- ======= Breadcrumbs ======= -->
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                    <h2>Our Template</h2>
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Our Template</li>
                    </ol>
                    </div>

                </div>
            </section><!-- End Breadcrumbs -->

            <!-- ======= Portfolio Section ======= -->
            <section id="pricing" class="pricing">
                <div class="container">

                    <div class="section-title">
                        <h2>Temukan Template Idealmu di Sini</h2>                    
                    </div>

                    <div class="col-md-12">
                        <ul id="features-filter" class="nav nav-pills mb-2 justify-content-center" >
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="pill" href="#pernikahan" role="tab" >Undangan Pernikahan</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="pill" href="#engagement" role="tab">Undangan Engagement</a>
                            </li>
                        </ul>
                    </div>

                    <div class="row justify-content-center">
                        <div class="tab-content" >
                            <!-- UNDANGAN SECTION -->
                            <div class="tab-pane fade show active" id="pernikahan" role="tabpanel" aria-labelledby="pernikahan-tab">
                                <div class="row justify-content-center">
                                    <div v-for="template in templates" :key="template.id" v-if="template.url.includes('wedding')" class="col-lg-3 col-md-6 mt-2">
                                        <div class="box featured">
                                            <span class="advanced" style="font-size: 10px;" v-if="template.template_name.includes('07') || template.template_name.includes('05') || template.template_name.includes('04') || template.template_name.includes('09')" >Recommended</span>
                                            <h3>{{template.template_name}}</h3>
                                            <img :src="'https://admin.itsyourdayofficial.com/template/' + template.image" style="border-radius: 25px; height: 300px;" class="img-fluid" alt="...">
                                            <div class="btn-wrap row">
                                                <a :href="template.url" target="_blank" class="btn-buy mb-2" style="color: #6c7c9f; border: 2px solid #c8cedc; background: transparent"><i class="lni lni-eye"></i> Preview</a>
                                                <a href="https://forms.gle/6DDmh5fcF7bYfVtcA" target="_blank" class="btn-buy"><i class='bx bxs-cart' ></i> Pesan Sekarang</a>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                            <!-- ENGAGEMENT SECTION -->
                            <div class="tab-pane fade" id="engagement" role="tabpanel" aria-labelledby="engagement-tab">
                                <div class="row justify-content-center">
                                    <div v-for="template in templates" :key="template.id" v-if="template.url.includes('engagement')" class="col-lg-3 col-md-6 mt-2">
                                        <div class="box featured">                                        
                                            <h3>{{template.template_name}}</h3>
                                            <img :src="'https://admin.itsyourdayofficial.com/template/' + template.image" style="border-radius: 25px; height: 300px;" class="img-fluid" alt="...">
                                            <div class="btn-wrap row">
                                                <a :href="template.url" target="_blank" class="btn-buy mb-2" style="color: #6c7c9f; border: 2px solid #c8cedc; background: transparent"><i class="lni lni-eye"></i> Preview</a>
                                                <a href="https://forms.gle/6DDmh5fcF7bYfVtcA" target="_blank" class="btn-buy"><i class='bx bxs-cart' ></i> Pesan Sekarang</a>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        
                        </div>
                    </div>

                    <div class="col-md-12 justify-content-center" style="padding-top: 80px;">
                        <div id="container-ff56ead9bcd83fc02c99a9618457334d"></div>
                    </div>

                </div>
            </section>
            <!-- End Portfolio Section -->

        </main><!-- End #main -->
    </div>
    
</template>

<script>
export default {
  props: ['templates'],
  data() {
    return{

    }
  },
  mounted() {
    // Buat elemen <script> untuk memuat script eksternal
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.setAttribute('data-cfasync', 'false');
    scriptElement.src = '//pl24822780.profitablecpmrate.com/ff56ead9bcd83fc02c99a9618457334d/invoke.js';

    // Tambahkan script ke dalam elemen <div> dengan ID yang sesuai
    const container = document.getElementById('container-3eb57ed70d0a015ad11397dee26f9d45');
    container.appendChild(scriptElement);
  }
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #BCA37F;
  color: #ffffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f5f8;
  border-radius: 50px;
}
</style>