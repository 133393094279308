import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Template from "../views/Template.vue";
import PageNotFound from "@/components/404.vue";
import Seserahan from "../views/Seserahan.vue";
import SnK from "../views/Syarat&Ketentuan.vue";
import Sitemap from "@/views/Sitemap.vue";
import ClaimVoucher from "@/views/ClaimVoucher.vue";
import ClickAds from "@/views/ClickAds.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/template",
        name: "Template",
        component: Template,
    },
    {
        path: "/seserahan",
        name: "Seserahan",
        component: Seserahan,
    },
    {
        path: "/SnK",
        name: "SnK",
        component: SnK,
    },

    {
        path: "/claim-voucher",
        name: "ClaimVoucher",
        component: ClaimVoucher,
    },

    {
        path: "/clickAds",
        name: "ClickAds",
        component: ClickAds,
    },
    {
        path: "*",
        redirect: { name: "PageNotFound", status: 404 },
    },
    {
        path: "/not-found",
        name: "PageNotFound",
        component: PageNotFound,
    },
    {
        path: "/sitemap.xml",
        name: "sitemap",
        component: Sitemap,
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
    base: process.env.BASE_URL,
    // for auto scroll up after move router
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const found = router.options.routes.some((route) => route.path === to.path);
    if (!found) {
        next({ path: "/not-found", query: { redirect: to.fullPath } });
    } else {
        next();
    }
});

export default router;
