<template>
    <!-- ======= Footer ======= -->
    <footer id="footer">
        <!-- IKLAN NATIVE BANNER -->
        <!-- <div class="footer-top">
            <div class="container">
                
                <div id="container-3eb57ed70d0a015ad11397dee26f9d45"></div>
            </div>
        </div> -->
        <div class="footer-top">
            <div class="container">
                <div class="row">

                    <div class="col-lg-7 col-md-6 footer-contact">
                        <div class="row mb-3">
                            <div class="col-md-1">
                                <a href="index.html">
                                    <img src="assets/img/logo.png" alt="" style="width: 50px; height:50px;"/>
                                </a>
                            </div>
                            <div class="col-md-11 pt-2">
                                <h3 style="font-family: 'Oleo Script', Sans-serif;">It's Your Day</h3>
                            </div>                        
                        </div>
                        <p style="text-align: justify;">
                            It's Your Day adalah layanan yang hadir sebagai solusi untuk kebutuhan hari istimewa Anda. Kami menawarkan jasa penyewaan box seserahan (termasuk hiasan seserahan) dan pembuatan undangan digital untuk melengkapi momen istimewa Anda.
                        </p>
                    </div>

                    <div class="col-lg-1 col-md-6 footer-links">
                        <!-- <h4>Useful Links</h4>
                        <ul>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                        </ul> -->
                    </div>

                    <div class="col-lg-4 col-md-6 footer-links">
                        <h4>Our Services</h4>
                        <ul class="pt-3">
                            <li>
                                <i class="lni lni-whatsapp" ></i>&nbsp;
                                <a :href="'https://api.whatsapp.com/send?phone=62813160009990&text= Hai min, saya mau tanya untuk paket sewa box seserahan dan undangan digitalnya nih. Terima kasih!'" target="_blank">0813160009990</a>
                            </li>
                            <li>
                                <i class="lni lni-envelope" ></i>&nbsp;
                                <a href="jvascript:void(0)">itsyourday.business@gmail.com</a>
                            </li>
                            <li>
                                <i class="bx bxl-instagram"></i>&nbsp;
                                <a href="https://instagram.com/itsyourday_seserahan/">@itsyourday_seserahan</a>
                            </li>
                            <li>
                                <i class="bx bxl-instagram"></i>&nbsp;
                                <a href="https://www.instagram.com/itsyourday_digitalinvitation/">@itsyourday_digitalinvitation</a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
                        <h4>Join Our Newsletter</h4>
                        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                        <form action="" method="post">
                        <input type="email" name="email"><input type="submit" value="Subscribe">
                        </form>
                    </div> -->

                </div>
            </div>
        </div>
        
        <div class="container d-md-flex py-4">

            <div class="me-md-auto text-center text-md-start">
                <div class="copyright">
                    Developed by <strong>It's Your Day</strong>
                </div>
                <div class="credits">
                <!-- All the links in the footer should remain intact. -->
                <!-- You can delete the links only if you purchased the pro version. -->
                <!-- Licensing information: https://bootstrapmade.com/license/ -->
                <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/baker-free-onepage-bootstrap-theme/ -->
                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div>
            </div>
            <div class="social-links text-center text-md-right pt-3 pt-md-0">
                <!-- <a href="https://instagram.com/itsyourday_seserahan/" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="https://www.instagram.com/itsyourday_digitalinvitation/" class="instagram"><i class="bx bxl-instagram"></i></a> -->
            </div>
        </div>

            
    </footer><!-- End Footer -->
</template>

<script>
export default {
  mounted() {
    // Buat elemen <script> untuk memuat script eksternal
    // const scriptElement = document.createElement('script');
    // scriptElement.async = true;
    // scriptElement.setAttribute('data-cfasync', 'false');
    // scriptElement.src = '//pl20765570.highcpmrevenuegate.com/3eb57ed70d0a015ad11397dee26f9d45/invoke.js';

    // // Tambahkan script ke dalam elemen <div> dengan ID yang sesuai
    // const container = document.getElementById('container-3eb57ed70d0a015ad11397dee26f9d45');
    // container.appendChild(scriptElement);
  }
}
</script>